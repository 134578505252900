import { createContext, useCallback, useEffect, useMemo, useState } from 'react';
import useStorageService from 'src/service/storage-service';

export const AppContext = createContext();

const ORGANIZATION_STORAGE_KEY = 'everydvm_organization';

const AppContextProvider = ({ children }) => {

    const [organization, setOrganization] = useState(() => {
        const storedOrg = localStorage.getItem(ORGANIZATION_STORAGE_KEY);
        return storedOrg ? JSON.parse(storedOrg) : null;
    });
    
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    const storageSvc = useStorageService(setOrganization);

    // Callback function using useCallback to prevent re-renders:
    const handleGetOrganizationStorage = useCallback(() => {
        storageSvc.getOrganizationStorage()
            .catch(e => {
                setError(e);
            })
            .finally(() => setIsLoading(false));
        // ignoring the next line because this call back is working as a singleton and does not need a dependency array value
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        handleGetOrganizationStorage(); // Call the memoized function
    }, [handleGetOrganizationStorage]); // Dependency is now the memoized function

    // memoizing the context value before handing it back
    const contextValue = useMemo(() => ({
        organization,
        isLoading,
        error,
    }), [organization, isLoading, error]);

    return (
        <AppContext.Provider value={contextValue}>
            {children}
        </AppContext.Provider>
    );
};

export default AppContextProvider;